import React, { Component } from 'react';

import Window from './components/Window';
import ResourcesList from './components/ResourcesList';
import ResourcesListItem from './components/ResourcesListItem';
import CollapsableList from './components/CollapsableList';
import CollapsableListItem from './components/CollapsableListItem';

import VideoOverlay from './static/videos/water2.webm';
import VideoBytes from './static/videos/bytes.webm';

import ImageArt from './static/images/rr.png';
import ImageUnsplashLogo from './static/images/unsplash-logo.png';
import ImagePixabayLogo from './static/images/pixabay-logo.svg';
import ImageSplitShireLogo from './static/images/splitshire-logo.png';
import ImageBobRossLogo from './static/images/bobross-logo.png';
import ImageSassLogo from './static/images/sass-logo.svg';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isAnyOpen: false,
            pages: {
                resources: false,
                downloads: false,
                portfolio: false,
                skills: false,
                socials: false
            }
        };

        this.videoOverlayBytesRef = React.createRef();

        this.setPage = this.setPage.bind(this);
        this.closeAllPages = this.closeAllPages.bind(this);
    }

    componentDidMount() {
        this.videoOverlayBytesRef.current.playbackRate = 1.1;
    }

    updateGlobalPageState() {
        
    }

    closeAllPages() {
        this.setState((state) => {
            let newState = {
                ...state,
                isAnyOpen: false,
                pages: {
                    ...state.pages
                }
            }

            for(var index in newState.pages) {
                newState.pages[index] = false;
            }
            
            newState.isAnyOpen = false;

            return newState;
        });
    }

    setPage(page, st) {
        this.setState((state) => {
            let newState = {
                ...state,
                isAnyOpen: false,
                pages: {
                    ...state.pages,
                    [page]: st
                }
            }

            let o = false;
            for(var index in newState.pages) {
                if(newState.pages[index] == true) {
                    o = true;
                    break;
                }
            }
            
            newState.isAnyOpen = o;

            return newState;
        });
    }

    renderAbout() {
        let c = [];
        if(this.state.pages.resources) {
            c.push(
                <Window title="resources.exe" scrollable className="overlay-window" onClose={() => this.setPage("resources", false)}>
                    <p className="fresh">NOTE: These tools are not made by myself. These are just tools that I use on a daily basis that may make your life easier or cooler.</p>
                    <h5 className="label">Tools</h5>
                    <ResourcesList>
                        <ResourcesListItem title="Brumm Soft Shadows" subtitle="soft shadows CSS generator" iconClass="fas fa-cloud-moon" iconColor="#b2a369" link="https://brumm.af/shadows" />
                        <ResourcesListItem title="Bob Ross Lorem Ipsum" subtitle="happy little paragraph generator" image={ImageBobRossLogo} iconColor="#aaf475" link="https://www.bobrosslipsum.com/" />
                        <ResourcesListItem title="Sass Cheatsheet" subtitle="neat quick-look guide for sass" image={ImageSassLogo} link="https://devhints.io/sass" />
                        <ResourcesListItem title="Flat UI Colors" subtitle="beautiful color palettes for UX" iconClass="fas fa-palette" link="https://flatuicolors.com/" />
                    </ResourcesList>

                    <h5 className="label">Stock Images</h5>
                    <ResourcesList>
                        <ResourcesListItem title="Unsplash" subtitle="free stock images" image={ImageUnsplashLogo} link="https://unsplash.com/" />
                        <ResourcesListItem title="Pixabay" subtitle="free stock images" image={ImagePixabayLogo} link="https://pixabay.com/" />
                        <ResourcesListItem title="SplitShire" subtitle="free stock images" image={ImageSplitShireLogo} link="https://www.splitshire.com/splitshire-free-stock-photos/" />
                    </ResourcesList>
                </Window>
            )
        }
        if(this.state.pages.downloads) {
            c.push(
                <Window title="downloads.exe" fit className="overlay-window" onClose={() => this.setPage("downloads", false)}>
                    <div className="midtext">
                        No downloads available.
                    </div>
                </Window>
            )
        }
        if(this.state.pages.portfolio) {
            c.push(
                <Window title="portfolio.exe" scrollable className="overlay-window" onClose={() => this.setPage("portfolio", false)}>

                    <CollapsableList content="Clients and Works">
                        <CollapsableListItem content="BOS Trading Card Game" link="https://bostcg.com/"/>
                        <CollapsableListItem content="AquaSentry Tampa" link="https://aquasentrytampa.com/"/>
                        <CollapsableListItem content="FQ Specialty Chemicals, USA" link="http://www.fq-chemusa.com/"/>
                        <CollapsableListItem content="New System Ethics" link="https://newsystemsethic.com"/>
                        <CollapsableListItem content="Full30.com (discontinued)" link="https://full30.com"/>
                        <CollapsableListItem content="FLPolyCon (discontinued)" link="https://flpolycon.org/"/>
                        <CollapsableListItem content="FLPolyCon 2020" link="https://polycon-react-site.now.sh/"/>
                        <CollapsableListItem content="FLPolyCon 2019" link="http://old.flpolycon.org/"/>
                        <CollapsableListItem content="PitchMadness (sketch/discontinued)" link="https://pitchmadness-react.now.sh/"/>
                        <CollapsableListItem content="Head Down Firearms (sketch/discontinued)" link="https://rs-headdown-august2019-2.now.sh/"/>
                    </CollapsableList>

                    <CollapsableList content="Past Work">
                        <CollapsableListItem content="1.FM" link="https://1.fm" />
                        <CollapsableListItem content="Toor Knives" link="https://toorknives.com/"/>
                        <CollapsableListItem content="Nut's n More" link="https://nuts-n-more.com/"/>
                        <CollapsableListItem content="Hoopwerx" link="https://www.hoopwerx.com/"/>
                        <CollapsableListItem content="Javapresse" link="https://www.javapresse.com/"/>
                        <CollapsableListItem content="Curves 'n Combat Boots" link="https://curvesncombatboots.com/"/>
                    </CollapsableList>

                    {/* <CollapsableList content="Partnerships">
                        <CollapsableListItem content="NinjaPineapple"/>
                        <CollapsableListItem content="RPCreativ"/>
                    </CollapsableList> */}

                    <CollapsableList content="Projects">
                        <CollapsableListItem content="Boulder - reddit surfer" link="https://boulder-react.vercel.app/"/>
                    </CollapsableList>
                </Window>
            )
        }
        if(this.state.pages.skills) {
            c.push(
                <Window title="skills.exe" scrollable className="overlay-window" onClose={() => this.setPage("skills", false)}>
                    <div className="c">
                        <div className="d-half m-full" style={{fontSize: '14px', fontFamily: 'Arial', paddingRight: '20px'}}>
                        <p style={{marginTop: '0px'}}>I'm a software developer and graphic designer at heart. With 9 years of experience creating projects and designs, building a refreshing taste of minimal design brings me much joy!</p>

                        <p>I build all types of experiences for everything from small business sites to full stack progressive web apps to mobile apps in the app store to APIs and microservices. I have expertise in HTML5, CSS, Sass, React, Node.js, Python, PHP, WordPress, and SEO!</p>

                        <p><b>Want to create an online store and presence?</b> We can build you a solution that fits your business model and style, small or large, and use analytics to keep track of your businesses growth and and analyze its key performance indicators for agility in your market.</p>

                        <p><b>Want a distributed infrastructure, or a single end site?</b> We can craft a model and system schematic that supports every one of your specifications with support for horizontal or vertical scaling. I have expertise building social media platforms, video and audio streaming services, live streaming services, and other enterprise architecture. We create efficient high traffic backend systems with task queueing to handle heavy computation costs with order. </p>

                        <p><b>Need your store fixed, an app installed, a new section, site optimization?</b> With experience with WooCommerce (WordPress) and Shopify (Liquid), we can fix theme and styling issues, app integrations, plugin issues and tailor solutions for your situation.</p>

                        <p><b>Just want a logo, graphic, or wireframe designed?</b> We can bring some spark to your branding and show off your projects with something unique and exciting.</p>

                        <p>I use Agile methodologies to drive our collaborative workflows and deliver high quality projects with speed and adaptability.</p>

                        <p>Let's work together on something great!</p>

                        <p style={{margin: "0px 0px 3px 0px"}}>- Raphael</p>
                        <a style={{marginBottom: "15px", display: "block"}}href="mailto:restraelle@gmail.com">restraelle@gmail.com</a>
                        </div>
                        <div className="d-half m-full">
                            <CollapsableList content="Web Design">
                                <CollapsableListItem content="HTML5"/>
                                <CollapsableListItem content="CSS & Sass using BEM"/>
                                <CollapsableListItem content="Landing Pages"/>
                                <CollapsableListItem content="Wireframes"/>
                                <CollapsableListItem content="Progressive Web Apps"/>
                                <CollapsableListItem content="E-Commerce Platforms"/>
                                <CollapsableListItem content="JavaScript & TypeScript"/>
                                <CollapsableListItem content="Responsive Design"/>
                                <CollapsableListItem content="Search Engine Optimization (SEO) Configuration"/>
                                <CollapsableListItem content="Content Management System Setup"/>
                            </CollapsableList>
                            <CollapsableList content="E-Commerces & CMS Software">
                                <CollapsableListItem content="Shopify (Liquid)"/>
                                <CollapsableListItem content="WordPress Theming"/>
                                <CollapsableListItem content="WordPress Plugins"/>
                                <CollapsableListItem content="Elementor"/>
                                <CollapsableListItem content="WooCommerce"/>
                                <CollapsableListItem content="Flatsome"/>
                                <CollapsableListItem content="Strapi"/>
                                <CollapsableListItem content="Stripe"/>
                            </CollapsableList>
                            <CollapsableList content="Progressive Web Application Development">
                                <CollapsableListItem content="React"/>
                                <CollapsableListItem content="Node.js"/>
                                <CollapsableListItem content="Sass"/>
                                <CollapsableListItem content="MobX"/>
                                <CollapsableListItem content="Redux"/>
                                <CollapsableListItem content="Bootstrap"/>
                                <CollapsableListItem content="Tailwind"/>
                                <CollapsableListItem content="Svelte"/>
                            </CollapsableList>
                            <CollapsableList content="Application Backend Programming">
                                <CollapsableListItem content="Python"/>
                                <CollapsableListItem content="Falcon"/>
                                <CollapsableListItem content="FastAPI"/>
                                <CollapsableListItem content="MongoEngine"/>
                                <CollapsableListItem content="peewee"/>
                                <CollapsableListItem content="Werkzeug"/>
                                <CollapsableListItem content="Hug"/>
                                <CollapsableListItem content="Flask"/>
                                <CollapsableListItem content="SQLAlchemy"/>
                                <CollapsableListItem content="Jinja"/>
                            </CollapsableList>
                            <CollapsableList content="Services & Databases">
                                <CollapsableListItem content="MongoDB"/>
                                <CollapsableListItem content="PostgreSQL"/>
                                <CollapsableListItem content="SQLite"/>
                                <CollapsableListItem content="MySQL"/>
                                <CollapsableListItem content="Apache Cassandra"/>
                                <CollapsableListItem content="Amazon DynamoDB"/>
                            </CollapsableList>
                            <CollapsableList content="Various Frameworks, Libraries, and Tools">
                                <CollapsableListItem content="Jupyter"/>
                                <CollapsableListItem content="Requests"/>
                                <CollapsableListItem content="Pandas"/>
                                <CollapsableListItem content="Numpy"/>
                                <CollapsableListItem content="Matplotlib"/>
                                <CollapsableListItem content="Celery"/>
                                <CollapsableListItem content="Grafana"/>
                                <CollapsableListItem content="Selenium"/>
                                <CollapsableListItem content="BeautifulSoup"/>
                                <CollapsableListItem content="Tkinter"/>
                                <CollapsableListItem content="LXML"/>
                                <CollapsableListItem content="discord.py"/>
                                <CollapsableListItem content="Stripe"/>
                            </CollapsableList>
                            {/* <CollapsableList content="Systems Programming">
                                <CollapsableListItem content="Rust"/>
                                <CollapsableListItem content="C++"/>
                                <CollapsableListItem content="Lua"/>
                            </CollapsableList> */}
                            <CollapsableList content="Mobile Application Development">
                                <CollapsableListItem content="Flutter"/>
                                <CollapsableListItem content="Ionic"/>
                                <CollapsableListItem content="React PWA"/>
                            </CollapsableList>
                            <CollapsableList content="System Administration">
                                <CollapsableListItem content="UNIX"/>
                                <CollapsableListItem content="Linux"/>
                                <CollapsableListItem content="Ubuntu"/>
                                <CollapsableListItem content="Window"/>
                                <CollapsableListItem content="Mac OS"/>
                                <CollapsableListItem content="NGINX"/>
                                <CollapsableListItem content="Docker"/>
                                <CollapsableListItem content="Cron Jobs"/>
                                <CollapsableListItem content="Celery"/>
                                <CollapsableListItem content="RabbitMQ"/>
                                <CollapsableListItem content="Redis"/>
                                <CollapsableListItem content="CloudFlare"/>
                                <CollapsableListItem content="Service Management"/>
                                <CollapsableListItem content="Domain, DNS, and Host Configuration"/>
                                <CollapsableListItem content="CloudFlare"/>
                            </CollapsableList>
                            <CollapsableList content="Graphics, UX Design, and Motion">
                                <CollapsableListItem content="Adobe Photoshop"/>
                                <CollapsableListItem content="Adobe Illustrator"/>
                                <CollapsableListItem content="Adobe After Effects"/>
                                <CollapsableListItem content="Adobe Premiere Pro"/>
                            </CollapsableList>
                            <CollapsableList content="Computer Aided Design & Prototyping">
                                <CollapsableListItem content="Blender"/>
                                <CollapsableListItem content="SketchUp"/>
                                <CollapsableListItem content="Arduino"/>
                            </CollapsableList>
                        </div>
                    </div>
                </Window>
            )
        }
        if(this.state.pages.socials) {
            c.push(
                <Window title="socials.exe" className="overlay-window" onClose={() => this.setPage("socials", false)}>
                </Window>
            )
        }

        return c;
    }
    render() {
        return (
            <div className="desk">
                <Window title="raph.sh" width={370} className={"main-window" + (this.state.isAnyOpen ? " main-window--shifted" : "")} statusBarItems={["linkedin.com/in/restraelle/", "Raph#1783"]}>
                    <div>
                        <p><b>Raphael Restrepo</b> | <a href="http://raph.sh">raph.sh</a></p>
                        <p>web & systems engineer</p>
                        <p></p>
                        <p><b><i>Specifications</i></b></p>
                        <ul style={{maxWidth: "220px"}}>
                            <li>9+ years of experience </li>
                            <li>Build websites, web apps, mobile apps, and software - front to back</li>
                            <li>Create microservices with RESTful APIs</li>
                            <li>Find and troubleshoot issues and create solutions</li>
                            {/* <li>Software Engineer | Full-Stack | Troubleshooting | HTML5/CSS/JS/TS | React | Node | PHP | Flutter | Shopify | WordPress | Python | Linux | Docker | MongoDB | PostgreSQL</li> */}
                        </ul>

                        <p>Let's get in touch and make something cool!</p>
                        <p style={{margin: "0px 0px 3px 0px"}}>- Raphael</p>
                        <a style={{marginBottom: "15px", display: "block"}}href="mailto:restraelle@gmail.com">restraelle@gmail.com</a>
                    </div>
                    <div className="nav">
                        <button onClick={() => this.setPage("skills", true)}>Skills</button>
                        <button onClick={() => this.setPage("portfolio", true)}>Portfolio</button>
                        <button onClick={() => this.setPage("resources", true)}>Resources</button>
                        <button onClick={() => this.setPage("downloads", true)}>Downloads</button>
                    </div>

                    <img src={ImageArt} alt="" style={{position: "absolute", bottom: "25px", right: "6px", borderRadius: "6px", opacity: "0.7", height: '90px', filter: 'invert(1)'}}/>

                    <button onClick={() => {window.location.href="mailto:restraelle@gmail.com"}} style={{marginRight: "5px"}}>Send E-mail</button>
                </Window>
                {this.renderAbout()}
                <div className={"desk-bg" + (this.state.isAnyOpen ? " desk-bg--darken" : "")}></div>
                <video ref={this.videoOverlayBytesRef} onClick={() => this.closeAllPages()} src={VideoBytes} muted loop autoPlay className={"desk-bytes-overlay" + (this.state.isAnyOpen ? " desk-bytes-overlay--active" : "")}></video>
                <video src={VideoOverlay} muted loop autoPlay className="desk-bg-overlay"></video>
            </div>
        );
    }
}

export default App;