import React, { Component } from 'react';

class ResourcesListItem extends Component {
    renderImage() {
        if(this.props.image) {
            return (
                <img src={this.props.image} alt=""/>
            );
        }
        if(this.props.iconClass) {
            return (
                <i className={this.props.iconClass} style={{color: (this.props.iconColor ? this.props.iconColor : "black")}}></i>
            );
        }
    }
    render() { 
        return (
            <a href={this.props.link} rel="noreferrer noopener nofollow" target="_blank" className="resources__item">
                <div className="resources__item__image">
                    {this.renderImage()}
                </div>
                <div className="resources__item__details">
                    <h4>{this.props.title}</h4>
                    <p>{this.props.subtitle}</p>
                </div>
            </a>
        );
    }
}
 
export default ResourcesListItem;