import React, { Component } from 'react';

class CollapsableList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        }
    }

    handle() {
        this.setState((state) => {
            return {
                ...state,
                isOpen: !state.isOpen
            };
        });
    }

    render() { 
        return (
            <React.Fragment>
                <h5 style={{margin: "0px 0px 4px 0px"}}>{this.props.content}</h5>
                <ul>
                    {this.props.children}
                </ul>
            </React.Fragment>
        );
    }
}
 
export default CollapsableList;