import React, { Component } from 'react';

class ResourcesList extends Component {
    render() { 
        return (
            <div className="resources">
                {this.props.children}
            </div>
        );
    }
}
 
export default ResourcesList;