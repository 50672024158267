import React, { Component } from 'react';

class CollapsableListItem extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    renderLink() {
        if(this.props.link) {
            return (
                <a target="_blank" style={{color: "lightblue", display: "inline-block", marginBottom: "5px"}} href={this.props.link}>{this.props.link}</a>
            )
        }
    }
    render() { 
        return (
            <li>
                <p style={{margin: "0px 0px 3px 0px"}}>{this.props.content}</p>
                {this.renderLink()}
            </li>
        );
    }
}
 
export default CollapsableListItem;