import React, { Component } from 'react';

class Window extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    handleOnClose() {
        if(this.props.onClose) {
            this.props.onClose();
        }
    }

    renderStatusBar() {
        if(this.props.statusBarItems != null) {
            return (
                <div className="status-bar">
                    {this.props.statusBarItems.map((i) => (
                        <div className="status-bar-field">{i}</div>
                    ))}
                </div>
            )
        }
    }

    renderOnClose() {
        if(this.props.onClose != null) {
            return (
                <div className="title-bar-controls">
                    <button aria-controls="Close" onClick={() => this.handleOnClose()}>X</button>
                </div>
            )
        }
    }

    render() { 
        return (
            <div className={"window" + (this.props.fit ? " overlay-window--fit" : "") + (this.props.className ? ` ${this.props.className}` : "")} style={{width: (this.props.width ? `${this.props.width}px` : "")}}>
                <div className="title-bar">
                    <div className="title-bar-text">{this.props.title ?? "window"}</div>
                    {this.renderOnClose()}
                </div>
                <div className={"window-body" + (this.props.scrollable ? " window-body--scrollable" : "")}>
                    {this.props.children}
                </div>
                {this.renderStatusBar()}
            </div>
        );
    }
}
 
export default Window;